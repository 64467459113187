<template>
  <div>
    <v-row class="row--35" align="center">
      <v-col lg="6" md="6" cols="12" order="2" order-md="1">
        <div class="about-inner inner">
          <div class="section-title">
            <h2 class="heading-title" style="color:#fff">About</h2>
            <p class="description">
              We are based out of Austin Texas and we are a group with a passion for technology and we were never happy with the software we ran across in the HOA space. So we built Property361 to simplify HOA management. Wether you are self managed or a management company. We wanted to cater our software for both scenarios.
            </p>
          </div>
          <div class="mt--30">
            <h3 style="color:#fff">Our software in a nutshell.</h3>
            <ul class="list-style--1">
              <li v-for="list in listOne" :key="list.id">
                <i v-html="iconSvg(list.icon)"></i>
                {{ list.desc }}
              </li>
            </ul>
          </div>
          <div class="mt--30">
            <h4>More Features.</h4>
            <ul class="list-style--1">
              <li v-for="list in listTwo" :key="list.id">
                <i v-html="iconSvg(list.icon)"></i>
                {{ list.desc }}
              </li>
            </ul>
          </div>
        </div>
      </v-col>
      <v-col lg="6" md="6" cols="12" order-md="2" order="1">
        <div class="thumbnail position-relative">
          <CoolLightBox :items="items" :index="index" @close="index = null">
          </CoolLightBox>

          <img class="w-100" src="/img/ai1.aeb767f5.jpg" alt="About Images" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `HOA Website with your brand`,
          },
          {
            id: 2,
            icon: "check",
            desc: `CC&R Management`,
          },
          {
            id: 3,
            icon: "check",
            desc: `Home Owners Mobile App`,
          },
          {
            id: 4,
            icon: "check",
            desc: `Accounting`,
          },
        ],
        listTwo: [
          {
            id: 1,
            icon: "check",
            desc: `Online Payments`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Vendor Management`,
          },
          {
            id: 3,
            icon: "check",
            desc: `Voting and Ballots`,
          },
          {
            id: 4,
            icon: "check",
            desc: `Smart Reporting`,
          },
        ],
        items: [
          {
            thumb: require("../../assets/images/about/about-3.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
